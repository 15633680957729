<template>
  <div class="flex w-full flex-col">
    <span v-if="title" class="mt-2"> {{ title }}</span>
    <div class="flex w-full">
      <sun-input
        disabled
        :value="value || noData"
        class-input="shadow-r-none border-r-none rounded-r-none"
        @click="onClickInput"
      />
      <button
        id="copy-clipboard"
        type="button"
        class="hover:bg-gray-400 action-button align-bottom p-2 shadow border py-2 px-3 rounded h-10 border-gray-300 rounded-l-none shadow-l-none border-l-none"
        :class="{ 'cursor-not-allowed': !value }"
        :disabled="!value"
        @click.stop="copyText"
      >
        <copy-svg class="w-4 h-4" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CopySvg from '@/components/icons/CopySvg';
import { copyToClipboard } from '@/utils/copyToClipboard';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

export default {
  name: 'AsterixInputAndCopy',
  components: {
    CopySvg,
  },
  props: {
    id: { type: String, default: () => undefined },
    title: { type: String, default: () => undefined },
    isLoading: { type: Boolean, default: () => undefined },
    value: { type: String, default: () => '' },
  },
  data: () => ({
    noData: 'N/A',
  }),
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    copyToClipboard,
    copyText() {
      copyToClipboard(this.value);
      this.createToast(Toast.success('Success!', 'Text copied to clipboard'));
      this.$emit('copied');
    },
    onClickInput() {
      this.$emit('click:input', this.value);
    },
  },
};
</script>

<style scoped>
#copy-clipboard:active {
  outline: none;
  opacity: 0.5;
}
</style>
