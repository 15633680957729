var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex w-full flex-col" }, [
    _vm.title
      ? _c("span", { staticClass: "mt-2" }, [_vm._v(" " + _vm._s(_vm.title))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex w-full" },
      [
        _c("sun-input", {
          attrs: {
            disabled: "",
            value: _vm.value || _vm.noData,
            "class-input": "shadow-r-none border-r-none rounded-r-none",
          },
          on: { click: _vm.onClickInput },
        }),
        _c(
          "button",
          {
            staticClass:
              "hover:bg-gray-400 action-button align-bottom p-2 shadow border py-2 px-3 rounded h-10 border-gray-300 rounded-l-none shadow-l-none border-l-none",
            class: { "cursor-not-allowed": !_vm.value },
            attrs: {
              id: "copy-clipboard",
              type: "button",
              disabled: !_vm.value,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.copyText.apply(null, arguments)
              },
            },
          },
          [_c("copy-svg", { staticClass: "w-4 h-4" })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }